import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { amp, ampProps } from '../../utils/amplitude'
import { writeUserData } from '../../utils/firebaseStorage'
import { v4 as uuidv4 } from 'uuid'
import './Success.scss'

const queryString = require('query-string');

const links = {
    'Cleaning': 'https://habit-box-web.s3.us-east-2.amazonaws.com/cleaning.pdf',
    'Nutrition': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Nutrition.pdf',
    'Personal Development': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Personal+Development.pdf',
    'Self-Care': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Self-Care.pdf',
    'Sex & Love': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Sex+%26+Love.pdf',
    'Sport': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Sport.pdf',
    'Mental & Physical Health': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Mental%26Physical+Health.pdf'
}
function Success(props) {
    useEffect(()=>{
        amp('success_screen_shown')
    },[])
    const openInNewWindow = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
    if (!(queryString.parse(props.location.search).billed === 'true' || props.user.billed)) return <Redirect to='/personality' />
    return (
        <div className="success">
            <img className="success__img" src="/img/success/success2.png" alt="img" style={{ marginBottom: 50 }} />
            <p className="success__header" style={{ marginTop: -40, position: 'relative' }}>Success!</p>
            <p className="success__body" style={{ marginTop: 0 }}>You already have your personal plan!</p>
            <p className="success__underbody">Check your email 😉</p>
            <button className="success__button" onClick={() => openInNewWindow(links[((props.user.firstClicked) ? props.user.firstClicked : 'Cleaning')])}> <img src="/img/success/pdf.png" alt="img" />Open Habit Plan</button>
            {(props.user.upsale1) ? <button className="success__button" style={{ marginTop: 12, background: '#15C963' }} onClick={() => openInNewWindow('https://habit-box-web.s3.us-east-2.amazonaws.com/extra/extra+challenges.pdf')}> <img src="/img/success/pdf.png" alt="img" />Open Workbook</button> : <></>}
            {(true) ? <div className="habitbox-app__container">
                <p className="container__text1">You could get premium access to app via your email. Just enter it in the app</p>
                <div className="container__buttons">
                    <img className="buttons__button" onClick={() => openInNewWindow('https://apps.apple.com/us/app/habitbox-challenges-habits/id1541469491')} src='/img/success/appstore.png' />
                    <img className="buttons__button" onClick={() => openInNewWindow('https://play.google.com/store/apps/details?id=fl.productivity.app')} src="/img/success/googleplay.png" />
                </div>
            </div> : <></>}
        </div>
    )
}
export default connect(state => ({ user: state.user }), dispatch => ({
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setSuccessEmail: () => {
        dispatch({ type: 'SET_SUCCESS_EMAIL' })
    }
}))(withRouter(Success))
