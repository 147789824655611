import React, { useEffect, useState } from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import Router from './Router';
import { ampSet } from './utils/amplitude';


function App(props) {
  const [flow, setFlow] = useState(parseInt(localStorage.getItem('flow_type')))
  const [zoom, setZoom] = useState((window.innerWidth / 375 > 1.33) ? 1.33 : window.innerWidth / 375)
  useEffect(() => {
    let params = queryString.parse(props.location.search)
    if(!localStorage.getItem('search')){
      localStorage.setItem('search',props.location.search)
    }
    Object.keys(params).forEach((elem) => {
      ampSet(elem, params[elem])
    })
  }, [props.location.search])

  useEffect(() => {
    window.addEventListener('resize', () => setZoom((window.innerWidth / 375 > 1.33) ? 1.33 : window.innerWidth / 375))
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location.pathname])
  return (
    <div className="App" style={{ zoom }}>
      <Router type={queryString.parse(props.location.search).flow_type}/>
      <img src="/img/utils/plan.png" className="none-image" alt="img" />
      <img src="/img/utils/like.png" className="none-image" alt="img" />
      <img src="/img/utils/bill.png" className="none-image" alt="img" />
      <img src="/img/home/main.png" className="none-image" alt="img" />
      <img src="/img/home/close.png" className="none-image" alt="img" />
      <img src="/img/header/Back.png" className="none-image" alt="img" />
      <img src="/img/header/Close.png" className="none-image" alt="img" />
      <img src="/img/header/Contact.png" className="none-image" alt="img" />
      <img src="/img/header/Menu.png" className="none-image" alt="img" />
      <img src="/img/header/Privacy.png" className="none-image" alt="img" />
      <img src="/img/header/Terms.png" className="none-image" alt="img" />
      <img src="/img/questionbox/bottomline.png" className="none-image" alt="img" />
      <img src="/img/questionImages/1/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/1/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/1/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/4/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/4/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/4/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/4.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/4.png" className="none-image" alt="img" />

      <img src="/img/questionImages/15/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/15/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/15/3.png" className="none-image" alt="img" />

      <img src="/img/questionImages/16/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/16/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/16/3.png" className="none-image" alt="img" />

      <img src="/img/questionImages/19/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/19/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/19/3.png" className="none-image" alt="img" />

      <img src="/img/questionImages/33/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/33/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/33/3.png" className="none-image" alt="img" />


      <img src="/img/email/email.png" className="none-image" alt="img" />
      <img src="/img/personality/customers1.png" className="none-image" alt="img" />
      <img src="/img/personality/customers2.png" className="none-image" alt="img" />
      <img src="/img/personality/customers3.png" className="none-image" alt="img" />
      <img src="/img/personality/customers4.png" className="none-image" alt="img" />
      <img src="/img/personality/customers5.png" className="none-image" alt="img" />
      <img src="/img/personality/left.png" className="none-image" alt="img" />
      <img src="/img/personality/logo.png" className="none-image" alt="img" />
      <img src="/img/personality/mastercard.png" className="none-image" alt="img" />
      <img src="/img/personality/minus.png" className="none-image" alt="img" />
      <img src="/img/personality/personality1.png" className="none-image" alt="img" />
      <img src="/img/personality/personality2.png" className="none-image" alt="img" />
      <img src="/img/personality/personality3.png" className="none-image" alt="img" />
      <img src="/img/personality/personality4.png" className="none-image" alt="img" />
      <img src="/img/personality/personality5.png" className="none-image" alt="img" />
      <img src="/img/personality/personality6.png" className="none-image" alt="img" />
      <img src="/img/personality/personality7.png" className="none-image" alt="img" />
      <img src="/img/personality/personality8.png" className="none-image" alt="img" />
      <img src="/img/personality/personality9.png" className="none-image" alt="img" />
      <img src="/img/personality/personality10.png" className="none-image" alt="img" />
      <img src="/img/personality/personality11.png" className="none-image" alt="img" />
      <img src="/img/personality/personality12.png" className="none-image" alt="img" />
      <img src="/img/personality/personality13.png" className="none-image" alt="img" />
      <img src="/img/personality/personality14.png" className="none-image" alt="img" />
      <img src="/img/personality/personality15.png" className="none-image" alt="img" />
      <img src="/img/personality/plus.png" className="none-image" alt="img" />
      <img src="/img/personality/right.png" className="none-image" alt="img" />
      <img src="/img/personality/roadmap.png" className="none-image" alt="img" />
      <img src="/img/personality/rocket.png" className="none-image" alt="img" />
      <img src="/img/personality/visa.png" className="none-image" alt="img" />
      <img src="/img/personality/whiteblank1.png" className="none-image" alt="img" />
      <img src="/img/personality/whiteblank2.png" className="none-image" alt="img" />
      <img src="/img/personality/whiteblank3.png" className="none-image" alt="img" />
      <img src="/img/personality/whiteblank4.png" className="none-image" alt="img" />

    </div>
  );
}

export default withRouter(App);
