import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { ampProps, ampSet } from '../../../utils/amplitude'
import { ConfirmButton, Header, QuestionBox } from '../../exporter'
function TextAnswers(props) {
    const [active, setActive] = useState(0)
    const [button, setButton] = useState(false)
    const [answered,setAnswered] = useState(false)
    const [animation,setAnimation] = useState(false)
    const [disabled,setDisabled] = useState(false)
    useEffect(() => {
        if (active) {
            setButton(true)
        } else {
            setButton(false)
        }
    }, [active])
    useEffect(()=>{
        if(answered){
            setAnswered(false)
        }
    },[answered])
    const activeHandler = (index) => {
        setDisabled(true)
        if (index === active) {
            setActive(0)
        } else {
            setActive(index)
        }
        setTimeout(()=>{
            setAnimation(true)
            if(props.questions[props.match.params.id-1].tag && props.questions[props.match.params.id-1].tag === 'gender'){
                ampSet('gender',props.questions[props.match.params.id-1].answers[index-1])
            }
            setTimeout(()=>{
                setActive(false)
                setDisabled(false)
                setAnswered(true)
                setAnimation(false)
            },400)
        },400)
        
    }
    if (answered) return <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`}/>
    return (
        <>
            <Header question={`${props.match.params.id}/${props.questions.length}`} back={!(props.location.pathname === '/question/1' || props.location.pathname === '/question/1/')} progress={parseInt(props.match.params.id) / props.questions.length * 100}/>
            <div className={(animation)?"text-answers__block text-answers__block--fadeout":"text-answers__block text-answers__block--fadein"}>
                <QuestionBox title={props.questions[parseInt(props.match.params.id - 1)].title} text={props.questions[parseInt(props.match.params.id - 1)].secondText} />
                <div className="block__container" style={(disabled)?{pointerEvents:'none'}:{}}>
                    {props.questions[parseInt(props.match.params.id - 1)].answers.map((elem, index) => {
                        return (
                            <div className={(active === index + 1) ? "container__item container__item--active" : "container__item container__item--unactive"} onClick={() => activeHandler(index + 1)} style={{padding:0}}>
                                <img src={`/img/questionImages/${props.match.params.id}/${index + 1}.png`} className="container-item__img" alt="img" />
                                <p className={(active === index + 1) ? "container-item__text container-item__text--active" : "container-item__text container-item__text--unactive"}style={{marginLeft:10,marginRight:10}}>{elem}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(TextAnswers))