import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { amp, ampSet } from '../../utils/amplitude'
import { ConfirmButton, Header } from '../exporter'
import './Email.scss'
var validator = require("email-validator");
function Email(props) {
    const [email, setEmail] = useState("")
    const [active, setActive] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const emailHandler = (e) => {
        setEmail(e.target.value)
    }
    useEffect(() => {
        amp('email_shown')
    }, [])
    useEffect(() => {
        setActive(validator.validate(email))
    }, [email])
    const openInNewWindow = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
    const submit = () => {
        ampSet('email', email)
        setActive(false)
        props.setEmail({ email: email })
        if(localStorage.getItem('type')){
            window.open(`/personality3${(localStorage.getItem('search'))?localStorage.getItem('search')+'&':'?'}good=${(props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1}&bad=${(props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1}&goal=${(props.questions[8].selected) ? props.questions[8].selected : 0}&email=${email}&firstClicked=${btoa(props.user.firstClicked)}`, "_top")
        }else{
            window.open(`/personality${(localStorage.getItem('search'))?localStorage.getItem('search')+'&':'?'}good=${(props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1}&bad=${(props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1}&goal=${(props.questions[8].selected) ? props.questions[8].selected : 0}&email=${email}&firstClicked=${btoa(props.user.firstClicked)}`, "_top")
        }
    }
    return (
        <div className="email__block">
            <img className="email__image" src='/img/email/email.png' alt="img" />
            <p className="email__header">It’s time!</p>
            <p className="email__text">Perk up your inbox and leave an email to be guided</p>
            <input type="email" className={(active) ? "email__input email__input--active" : "email__input email__input--unactive"} placeholder="email@email.com" value={email} onChange={emailHandler} />
            <div className="confirm" style={{ position: 'relative' }}>
                <button className={(active ? "confirm__button confirm__button--active" : "confirm__button confirm__button--unactive")} onClick={() => submit()}>Get my plan</button>
                <p className="email__text2">By continuing you indicate that you’ve read and agree to our <span onClick={() => openInNewWindow('/pdf/Terms.pdf')}>Terms & Conditions</span>, <span onClick={() => openInNewWindow('/pdf/Policy.pdf')}>Privacy Policy</span> and <span onClick={() => openInNewWindow('/pdf/Subsription.pdf')} >Subscriptions Terms</span>.</p>
            </div>
        </div>
    )

}


export default connect(state => ({ questions: state.questions,user: state.user }), dispatch => ({
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    }
}))(withRouter(Email))



