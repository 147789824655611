import React, { useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router'
import { ProgressBar } from 'react-bootstrap'
import './Header.scss'
function Header(props) {
    const [redirect, setRedirect] = useState(false)
    const goBack = () => {
        setRedirect(true)
    }
    useEffect(() => {
        if (redirect) {
            setRedirect(false)
        }
    }, [redirect])
    if (redirect) return <Redirect to={(props.match.params.id - 1) ? `/question/${props.match.params.id - 1}` : `/`} />
    return (

        <>
            <div className="header">
                <ProgressBar variant="warning" style={{ width: "100%", animation: 'none' }} now={props.progress || 60} />
                <div className="header__block">
                    <p className="header__text1" style={(props.match.params.id === '1')?{opacity:0,pointerEvents:'none'}:{opacity:1}}onClick={() => goBack()}><i class="fas fa-chevron-left" /> Previous step</p>
                    <p className="header__text2">{props.question}</p>
                </div>
            </div>
        </>
    )
}
export default withRouter(Header)