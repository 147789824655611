import amplitude from 'amplitude-js'

const ampInst = amplitude.getInstance();
ampInst.init('99704f5397b40a10b091813e48426ea3')

function amp(event){
    ampInst.logEvent(event)
}
function ampProps(event,props){
    ampInst.logEvent(event,props)
}
function ampSet(key,value){
    let identify = new amplitude.Identify().set(key, value)
    ampInst.identify(identify);
}

export {amp,ampProps,ampSet}