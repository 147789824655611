import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { ampProps } from '../../../utils/amplitude'
import { ConfirmButton, Header, QuestionBox,QuestionBox2 } from '../../exporter'
import './TextAnswers.scss'
function TextAnswers(props) {
    const [active, setActive] = useState(0)
    const [button, setButton] = useState(false)
    const [answered, setAnswered] = useState(false)
    const [animation, setAnimation] = useState(false)
    const [disabled,setDisabled] = useState(false)
    const answer = () => {
        // console.log('hello')
        // ampProps("question_answered", { question: props.match.params.id })
        setActive(false)
    }
    useEffect(() => {
        if (answered) {
            setAnswered(false)
        }
    }, [answered])
    useEffect(() => {
        if (active) {
            setButton(true)
        } else {
            setButton(false)
        }
    }, [active])
    const activeHandler = (index) => {
        setDisabled(true)
        if (index === active) {
            setActive(0)
        } else {
            setActive(index)
        }
        setTimeout(()=>{
            setAnimation(true)
            setTimeout(() => {
                setActive(false)
                setAnswered(true)
                setDisabled(false)
                setAnimation(false)
            }, 400)
        },400)
    }
    if (answered) return <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`} />
    return (
        <>
            <Header back={true} question={`${props.match.params.id}/${props.questions.length}`} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
            <div className={(animation) ? "text-answers__block text-answers__block--fadeout" : "text-answers__block text-answers__block--fadein"}>
                <QuestionBox2 title={props.questions[parseInt(props.match.params.id - 1)].title} text={props.questions[parseInt(props.match.params.id - 1)].secondText} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
                <div className="block__container" style={(disabled)?{pointerEvents:'none'}:{}}>
                    {props.questions[parseInt(props.match.params.id - 1)].answers.map((elem, index) => {
                        return (
                            <div className={(active === index + 1) ? "container__item container__item--active" : "container__item container__item--unactive"} onClick={() => activeHandler(index + 1)} >
                                <p className={(active === index + 1) ? "container-item__text container-item__text--active" : "container-item__text container-item__text--unactive"} style={{marginLeft:10}}>{elem}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(TextAnswers))