import React, { useEffect, useState } from 'react'
import './Discount.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Slider from './Slider/Slider'
import { Link } from 'react-router-dom'
import { Card, Accordion } from 'react-bootstrap'
import MyVerticallyCenteredModal from '../BillingModal/BillingModal'
import { Modal, Button } from 'react-bootstrap'
import { amp, ampSet } from '../../utils/amplitude'
import Footer from '../Footer/Footer'
import queryString from 'query-string';

function Personality(props) {
    const [price] = useState(1999)
    const [price2] = useState(69)
    const [show, setShow] = useState(false)
    const [plan, setPlan] = useState(1)
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        let params = queryString.parse(props.location.search)
        if (params.email) {
            if (Array.isArray(params.email)) {
                props.setEmail({ email: params.email[0] })
            } else {
                props.setEmail({ email: params.email })
            }

        }
        if(params.firstClicked){
            if (Array.isArray(params.firstClicked)) {
                props.setFirstClick({firstClicked:atob(params.firstClicked[0])})
            } else {
                props.setFirstClick({firstClicked:atob(params.firstClicked)})
            }
        }
    }, [])
    useEffect(() => {
        localStorage.setItem('hello', ((plan)?price2.toString():price.toString()))
    }, [plan])
    useEffect(() => {
        setTimeout(()=>{
            amp('discount_screen_shown')
        },0)
    }, [])
    return (
        <div className="personality" style={{ background: 'white' }}>
            <div className="successed" id="getplan" style={{ paddingBottom: 14, background: '#FEF7ED' }}>
                <img src="/img/personality/discount17.png" style={{ width: '80%' }} />
                <p className="personality__text" style={{ width: "80%", marginBottom: 10, marginTop: 15 }}><p style={{ color: '#4380FF' }}>Additional save 30% </p>for you</p>
                <div className={(!plan) ? "billing__plan billing__plan--active" : "billing__plan billing__plan--unactive"} onClick={() => setPlan(0)}>
                    <div className="plan__left-side">
                        <img src={`/img/utils/pick_${(!plan) ? "active" : "unactive"}.png`} style={{ width: 20 }} />
                    </div>
                    <div className="plan__right-side">
                        <div className="plan__text1" style={{flexDirection:'column',alignItems:'flex-start'}}><p style={{marginLeft:'5%'}}> Lifetime access</p><p style={{marginLeft:'10%'}}><span>19.99$</span><strike style={{opacity:0.5,fontWeight:500,marginLeft:8}}>29.99$</strike></p></div>
                        <p className="plan__text2" style={{textAlign:'center'}}>Personal habit plan &<br /> HabitBox app lifetime access</p>
                    </div>
                </div>
                <div className={(plan) ? "billing__plan billing__plan--active" : "billing__plan billing__plan--unactive"} onClick={() => setPlan(1)}>
                    <div className="plan__left-side">
                        <img src={`/img/utils/pick_${(plan) ? "active" : "unactive"}.png`} style={{ width: 20 }} />
                    </div>
                    <div className="plan__right-side">
                        <div className="plan__text1" ><p style={{marginLeft:'5%'}}> 1 month plan</p><p className={(plan) ? "price-on-sticker price-on-sticker--active" : "price-on-sticker price-on-sticker--unactive"}>for first<br /> week</p><img src="/img/utils/price.png" style={{ width: 100, height: 70, marginTop: -34 }} /></div>
                        <p className="plan__text1" style={{marginLeft:'10%',width:'40%'}}><span>0.69$</span><strike style={{opacity:0.5,fontWeight:500,marginLeft:8}}>0.99$</strike></p>
                        <p className="plan__text2" style={{marginLeft:'10%'}}>Personal habit plan & <br />HabitBox app access</p>
                    </div>
                </div>
                <img src="/img/utils/222.png" style={{ width: '75%', marginTop: 5 }} />
                <button className="successed__button" style={{ marginBottom: 0, width: '90%' }} onClick={() => setShow(true)}>{plan ? "Start trial" : "Get My Plan"}</button>
                <p className="get__text2" style={(plan) ? { marginBottom: 0, marginTop: 10 } : { marginBottom: 0, marginTop: 10, opacity: 0 }}>Cancel anytime<br/> <span style={{opacity:.5}}>7.99 US$/week after trial</span></p>

            </div>
            <p className="personality__text" style={{ width: "90%", marginBottom: 30, marginTop: 70 }}>What our <span style={{ color: '#4380FF' }}> clients say</span></p>
            <p className="successed__text2" style={{ width: "80%", textAlign: 'center' }}><span style={{ color: '#4380FF' }}>92% </span> of our clients built habits of successful life by following our custom habit forming plan!</p>
            <Slider />
            <p className="personality__text" style={{ width: "90%", marginBottom: 43, marginTop: 70 }}>Be the best version of <span style={{ color: '#4380FF' }}>yourself</span></p>
            <div className="personality__version" style={{background:'#F4F8FF'}}>
                <img src="/img/personality/discount5.png" style={{ width: 120 }} />
                <p className="version__text1">Today</p>
                <img src="/img/personality/discount7.png" style={{ width: 15 }} />
                <img src="/img/personality/discount6.png" style={{ width: 120 }} />
                <p className="version__text1">In 28 days</p>
                <p className="version__text2">You will improve health, life satisfaction, work performance and relationships with others</p>
                <button className="version__button" onClick={() => { setShow(true) }}>Get My Discount</button>
            </div>
            <p className="personality__text" style={{ width: "90%", marginBottom: 30, marginTop: 70 }}>100% money-back <span style={{ color: '#4380FF' }}>guarantee💵</span></p>
            <p className="personality__text2" style={{ marginBottom: 20 }}>If you feel that your plan won’t work for you, we guarantee to give you money back in <span style={{ color: '#4380FF' }}>14 days</span>.<br /><br /> But you know, there’s no way
it will dissapoint you ;) </p>

            <Footer />
            <MyVerticallyCenteredModal
                show={show}
                onHide={() => { handleClose(); props.discount() }}
                price={(plan) ? price2 : price}
                promo={true}
                subscription={plan}
                link={'/discount'}
                successRedirect={'/upsale2'}
            />
        </div>
    )
}

export default connect(state => ({ questions: state.questions, user: state.user }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    },
    discount: () => {
        dispatch({ type: 'DISCOUNT' })
    },
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setFirstClick:(payload) =>{
        dispatch({type:'SET_FIRST_CLICK',payload})
    }
}))(withRouter(Personality))