import React, { useEffect, useState } from 'react'
import './Personality.scss'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import MyVerticallyCenteredModal from '../BillingModal/BillingModal'
import { amp, ampSet } from '../../utils/amplitude'
import queryString from 'query-string';
import Footer from '../Footer/Footer'
const openInNewWindow = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer')
}

function Personality(props) {
    const [check,setCheck] = useState(true)
    const [acc1, setAcc1] = useState(false)
    const [acc2, setAcc2] = useState(false)
    const [acc3, setAcc3] = useState(false)
    const [flow, setFlow] = useState(null)
    const [good, setGood] = useState(0)
    const [bad, setBad] = useState(0)
    const [goal, setGoal] = useState(0)
    const [countdown, setCountDown] = useState("09:59")
    const [price, setPrice] = useState(2999)
    const [price2, setPrice2] = useState(99)
    const [plan, setPlan] = useState(1)
    const [time] = useState(Date.now() + 1000 * 60 * 10)
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [redirect, setDiscountRedirect] = useState(false)
    const [redirect2, setRedirect2] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [habbit, setHabbit] = useState('Follow your plan to start exercise')
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        localStorage.setItem('hello', ((plan)?price2.toString():price.toString()))
    }, [plan])
    useEffect(() => {
        setInterval(() => {
            let minutes = new Date(new Date(time) - new Date()).getMinutes()
            let seconds = new Date(new Date(time) - new Date()).getSeconds()
            setCountDown(`${(minutes < 10) ? "0" : ""}${minutes}:${(seconds < 10) ? "0" : ""}${seconds}`)
        }, 1000)
    }, [])
    useEffect(() => {
        console.log(plan)
    }, [plan])
    useEffect(() => {
        let counter = 0
        let params = queryString.parse(props.location.search)
        console.log(params)
        if (params.email) {
            if (Array.isArray(params.email)) {
                props.setEmail({ email: params.email[0] })
            } else {
                props.setEmail({ email: params.email })
            }

        }
        if (params.firstClicked) {
            if (Array.isArray(params.firstClicked)) {
                props.setFirstClick({ firstClicked: atob(params.firstClicked[0]) })
            } else {
                props.setFirstClick({ firstClicked: atob(params.firstClicked) })
            }
        }
        if (params.bad) {
            counter++
            if (Array.isArray(params.bad)) {
                setBad(params.bad[0])
            } else {
                setBad(params.bad)
            }
        }
        if (params.good) {
            counter++
            if (Array.isArray(params.good)) {
                setGood(params.good[0])
            } else {
                setGoal(params.good)
            }
        }
        if (params.goal) {
            counter++
            if (Array.isArray(params.goal)) {
                setGoal(params.goal[0])
            } else {
                setGoal(params.goal)
            }
        }
        if (params.email || props.user.email) {

        } else {
            setRedirect2(true)
        }
        if (counter === 3 && !localStorage.getItem('confirmation')) {
            localStorage.setItem('confirmation', 'true')
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/confirm`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "auth": process.env.REACT_APP_MAILFIRE
                },
                body: JSON.stringify({
                    email: params.email,
                    good: (props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1,
                    bad: (props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1,
                    goal: (props.questions[8].selected) ? props.questions[8].selected : 0,
                    ...(localStorage.getItem('flow_type') === 'app') ? { app_flow: 1 } : { app_flow: 0 },
                    url: props.location.pathname,
                    initial_source: (params.utm_source) ? params.utm_source : 'unknown_source'
                })
            })
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/data`, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "auth": process.env.REACT_APP_MAILFIRE
                },
                body: JSON.stringify({
                    email: (params.email) ? (Array.isArray(params.email)) ? params.email[0] : params.email : props.user.email,
                    good: (props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1,
                    bad: (props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1,
                    goal: (props.questions[8].selected) ? props.questions[8].selected : 0,
                    price: price,
                    initial_source: (params.utm_source) ? params.utm_source : 'unknown_source'
                })
            })
        }

    }, [props.location.search])
    useEffect(() => {
        let badHabbits = props.questions[11].selected
        badHabbits.forEach((elem, index) => {
            if (elem) {
                setHabbit(`Follow your plan to get rid of ${props.questions[11].answers[index].toLowerCase()}`)
            }
        })
    }, [props.questions])
    useEffect(() => {
        ampSet('flow_type', 'test')
        setTimeout(() => {
            amp('sales_screen_shown')
        }, 0)
    }, [])
    if (redirect2) return <Redirect to='/email' />
    if (redirect) return <Redirect to='/discount' />
    return (
        <div className="personality" style={{ background: 'white' }}>
            <div className="personality__header">
                <div className="header__logo"><p className="logo__text2">Special offer expires in</p><p className="logo__text">{countdown}</p></div>
                <a href="#getplan" className="header__button">Get My Plan</a>
            </div>
            <div className="personality__forecast">
                <p className="forecast__header">Forecast based on your answers</p>
                <div className="forecast__block">
                    <div className="block__absolute">
                        <p className="absolute__text1">Good habits forecast</p>
                        <p className="absolute__text2">{(good) ? good : (props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1} habits</p>
                    </div>
                    <img src="/img/personality/personality26.png" className="forecast__img" />
                </div>
                <div className="forecast__footer">
                    <div className="footer__block">
                        <p className="block__text1">Old bad habits</p>
                        <p className="block__text2">-{(bad) ? bad : (props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1} habits</p>
                    </div>
                    <div className="footer__block">
                        <p className="block__text1">Timing goal</p>
                        <p className="block__text3">{(goal) ? props.questions[8].answers[goal] : props.questions[8].answers[props.questions[8].selected]}<span className="block__text4"> day</span></p>
                    </div>
                </div>
            </div>

            <div className="successed" id="getplan" style={{ paddingBottom: 14 }}>
                <p className="successed__text1" style={{ width: "80%" }}>Habits raise our chance <span style={{ color: '#4380FF' }}> to succeed!</span></p>
                <p className="get__text2" style={(plan) ? { marginBottom: 25 } : { marginBottom: 25, opacity: 0 }}>7.99 US$/week after trial</p>
                <img src="/img/personality/personality33.png" style={{ width: '90%' }} />
                <div className={(!plan) ? "billing__plan billing__plan--active" : "billing__plan billing__plan--unactive"} onClick={() => setPlan(0)}>
                    <div className="plan__left-side">
                        <img src={`/img/utils/pick_${(!plan) ? "active" : "unactive"}.png`} style={{ width: 20 }} />
                    </div>
                    <div className="plan__right-side">
                        <div className="plan__text1"><p> Lifetime access</p><p>29.99$</p></div>
                        <p className="plan__text2">Personal habit plan &<br /> HabitBox app lifetime access</p>
                    </div>
                </div>
                <div className={(plan) ? "billing__plan billing__plan--active" : "billing__plan billing__plan--unactive"} onClick={() => setPlan(1)}>
                    <div className="plan__left-side">
                        <img src={`/img/utils/pick_${(plan) ? "active" : "unactive"}.png`} style={{ width: 20 }} />
                    </div>
                    <div className="plan__right-side">
                        <div className="plan__text1"><p> 1 month plan</p><p className={(plan) ? "price-on-sticker price-on-sticker--active" : "price-on-sticker price-on-sticker--unactive"}>0.99 first<br /> week</p><img src="/img/utils/price.png" style={{ width: 100, height: 70, marginTop: -34 }} /></div>
                        <p className="plan__text2">Personal habit plan & <br />HabitBox app access</p>
                    </div>
                </div>
                <img src="/img/utils/222.png" style={{ width: '75%', marginTop: 5 }} />
                <button className="successed__button" style={{ marginBottom: 0, width: '90%' }} onClick={() => setShow(true)}>{plan ? "Start trial" : "Get My Plan"}</button>
            </div>
            <p className="get__text2" style={(plan) ? { marginBottom: 25, marginTop: 10 } : { marginBottom: 25, marginTop: 10, opacity: 0 }}>Cancel anytime · $31.96 billed monthly</p>
            <p className="get__text2" style={(plan) ? { marginBottom: 25, marginTop: 10 } : { marginBottom: 25, marginTop: 10, opacity: 0 }}><input type="checkbox" checked = {check } onClick={()=>setCheck(!check)}/>By clicking the "Start trial" button below, you agree to our <span style={{color:'blue',textDecoration:'underline'}} onClick={()=>openInNewWindow('/pdf/Terms.pdf')}>Terms of Use</span>,<span style={{color:'blue',textDecoration:'underline'}} onClick={()=>openInNewWindow('/pdf/Subsciption.pdf')}>Subscription Policy</span> and <span style={{color:'blue',textDecoration:'underline'}} onClick={()=>openInNewWindow('/pdf/Policy.pdf')}>Privacy Policy</span>.
Your subscription renews automatically at the end of the 7-day trial period unless you cancel at least 1 day before the end of the trial period. To avoid being charged cancel your subscription by contacting our support via support@habit-box.me at least 1 day before the end of the trial period. If you are unsure how to cancel, visit our <span style={{color:'blue',textDecoration:'underline'}} onClick={()=>openInNewWindow('/pdf/Subsription.pdf')}>Subscription Terms</span></p>
            <p className="personality__text" style={{ width: "90%", marginBottom: 30, marginTop: 0 }}>100% money-back <span style={{ color: '#4380FF' }}> guarantee💵</span></p>
            <p className="personality__text2">If you feel that your plan won’t work for you, we guarantee to give you money back in <span style={{ color: '#4380FF' }}>14 days</span>.<br /><br /> But you know, there’s no way it will dissapoint you ;) </p>
            <Footer />
            <MyVerticallyCenteredModal
                show={show}
                onHide={() => { handleClose(); props.discount() }}
                price={(plan) ? price2 : price}
                link={`/discount?email=${props.user.email}&firstClicked=${btoa(props.user.firstClicked)}`}
                subscription={plan}
                successRedirect={'/upsale2'}
            />
        </div>
    )
}

export default connect(state => ({ questions: state.questions, user: state.user }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    },
    discount: () => {
        dispatch({ type: 'DISCOUNT' })
    },
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setFirstClick: (payload) => {
        dispatch({ type: 'SET_FIRST_CLICK', payload })
    }
}))(withRouter(Personality))