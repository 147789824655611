import React, { useEffect, useState } from 'react'
import './Personality.scss'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, Accordion } from 'react-bootstrap'
import MyVerticallyCenteredModal from '../BillingModal/BillingModal'
import { Modal, Button } from 'react-bootstrap'
import { amp, ampSet } from '../../utils/amplitude'
import queryString from 'query-string';
import Footer from '../Footer/Footer'

function Personality(props) {
    const [acc1, setAcc1] = useState(false)
    const [acc2, setAcc2] = useState(false)
    const [acc3, setAcc3] = useState(false)
    const [flow, setFlow] = useState(null)
    const [good, setGood] = useState(0)
    const [bad, setBad] = useState(0)
    const [goal, setGoal] = useState(0)
    const [countdown, setCountDown] = useState("09:59")
    const [price, setPrice] = useState(2999)
    const [price2, setPrice2] = useState(99)
    const [plan, setPlan] = useState(1)
    const [time] = useState(Date.now() + 1000 * 60 * 10)
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [redirect, setDiscountRedirect] = useState(false)
    const [redirect2, setRedirect2] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [habbit, setHabbit] = useState('Follow your plan to start exercise')
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        // window.fbq('track', 'sales_screen_shown');
        setInterval(() => {
            let minutes = new Date(new Date(time) - new Date()).getMinutes()
            let seconds = new Date(new Date(time) - new Date()).getSeconds()
            setCountDown(`${(minutes < 10) ? "0" : ""}${minutes}:${(seconds < 10) ? "0" : ""}${seconds}`)
        }, 1000)
    }, [])
    useEffect(() => {
        localStorage.setItem('hello', ((plan)?price2.toString():price.toString()))
    }, [plan])
    useEffect(() => {
        console.log(plan)
    }, [plan])
    useEffect(() => {
        let counter = 0
        let params = queryString.parse(props.location.search)
        console.log(params)
        if (params.email) {
            if (Array.isArray(params.email)) {
                props.setEmail({ email: params.email[0] })
            } else {
                props.setEmail({ email: params.email })
            }

        }
        if(params.firstClicked){
            if (Array.isArray(params.firstClicked)) {
                props.setFirstClick({firstClicked:atob(params.firstClicked[0])})
            } else {
                props.setFirstClick({firstClicked:atob(params.firstClicked)})
            }
        }
        if (params.bad) {
            counter++
            if (Array.isArray(params.bad)) {
                setBad(params.bad[0])
            } else {
                setBad(params.bad)
            }
        }
        if (params.good) {
            counter++
            if (Array.isArray(params.good)) {
                setGood(params.good[0])
            } else {
                setGoal(params.good)
            }
        }
        if (params.goal) {
            counter++
            if (Array.isArray(params.goal)) {
                setGoal(params.goal[0])
            } else {
                setGoal(params.goal)
            }
        }
        if (params.email || props.user.email) {

        } else {
            setRedirect2(true)
        }
        if (counter === 3 && !localStorage.getItem('confirmation')) {
            localStorage.setItem('confirmation', 'true')
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin)?process.env.REACT_APP_TEST_SERVER:process.env.REACT_APP_LIVE_SERVER}/api/mailfire/confirm`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "auth": process.env.REACT_APP_MAILFIRE
                },
                body: JSON.stringify({
                    email: params.email,
                    good: (props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1,
                    bad: (props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1,
                    goal: (props.questions[8].selected) ? props.questions[8].selected : 0,
                    ...(localStorage.getItem('flow_type') === 'app') ? { app_flow: 1 } : { app_flow: 0 },
                    url: props.location.pathname,
                    initial_source: (params.utm_source) ? params.utm_source : 'unknown_source'
                })
            })
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin)?process.env.REACT_APP_TEST_SERVER:process.env.REACT_APP_LIVE_SERVER}/api/mailfire/data`, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "auth": process.env.REACT_APP_MAILFIRE
                },
                body: JSON.stringify({
                    email: (params.email) ? (Array.isArray(params.email)) ? params.email[0] : params.email : props.user.email,
                    good: (props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1,
                    bad: (props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1,
                    goal: (props.questions[8].selected) ? props.questions[8].selected : 0,
                    price: price,
                    initial_source: (params.utm_source) ? params.utm_source : 'unknown_source'
                })
            })
        }

    }, [props.location.search])
    useEffect(() => {
        let badHabbits = props.questions[11].selected
        badHabbits.forEach((elem, index) => {
            if (elem) {
                setHabbit(`Follow your plan to get rid of ${props.questions[11].answers[index].toLowerCase()}`)
            }
        })
    }, [props.questions])
    useEffect(() => {
        ampSet('flow_type','original')
        setTimeout(() => {
            amp('sales_screen_shown')
        }, 0)
    }, [])
    if (redirect2) return <Redirect to='/email' />
    if (redirect) return <Redirect to='/discount' />
    return (
        <div className="personality" style={{ background: 'white' }}>
            <div className="personality__header">
                <div className="header__logo"><p className="logo__text2">Special offer expires in</p><p className="logo__text">{countdown}</p></div>
                <a href="#getplan" className="header__button">Get My Plan</a>
            </div>
            <div className="personality__forecast">
                <p className="forecast__header">Forecast based on your answers</p>
                <div className="forecast__block">
                    <div className="block__absolute">
                        <p className="absolute__text1">Good habits forecast</p>
                        <p className="absolute__text2">{(good) ? good : (props.questions[10].selected.filter((elem) => elem).length) ? props.questions[10].selected.filter((elem) => elem).length : 1} habits</p>
                    </div>
                    <img src="/img/personality/personality26.png" className="forecast__img" />
                </div>
                <div className="forecast__footer">
                    <div className="footer__block">
                        <p className="block__text1">Old bad habits</p>
                        <p className="block__text2">-{(bad) ? bad : (props.questions[11].selected.filter((elem) => elem).length) ? props.questions[11].selected.filter((elem) => elem).length : 1} habits</p>
                    </div>
                    <div className="footer__block">
                        <p className="block__text1">Timing goal</p>
                        <p className="block__text3">{(goal) ? props.questions[8].answers[goal] : props.questions[8].answers[props.questions[8].selected]}<span className="block__text4"> day</span></p>
                    </div>
                </div>
            </div>
            <div className="personality__day">
                <p className="day__header">We are what we do <span style={{ color: '#4380FF' }}>every day</span></p>
                <p className="day__text1">To successfully form a habit, you need to:</p>
                <div className="day__picks">
                    <div className="pick">
                        <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Get a plan</p>
                    </div>
                    <div className="pick">
                        <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Define routine</p>
                    </div>
                    <div className="pick">
                        <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Set a trigger</p>
                    </div>
                    <div className="pick">
                        <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Reward yourself</p>
                    </div>
                </div>
                <p className="day__text2" style={{ marginBottom: 40 }}>Also, you need to set a clear goal in mind. We'll help you form and take control over your habits, and lead you to the patterns of a happy life. <br /><br />By following personal plan, you'll form good habits for a meaningful life.</p>
            </div>
            <img src="/img/personality/personality27.png" style={{ width: '100%' }} />
            <p className="personality__text" style={{ marginTop: 50, marginBottom: 30 }}>What you <span style={{ color: '#4380FF' }}>get</span></p>
            <img src="/img/personality/personality32.png" style={{ width: "80%" }} />
            <p className="get__text1">Premium Access</p>
            <p className="get__text2">You’ll get premium access to app that helps you plan your schedule for new healthy habits.</p>
            <img src="/img/personality/personality31.png" style={{ width: "80%" }} />
            <p className="get__text1">Progress Tracker </p>
            <p className="get__text2">You can track your progress each time you need. Monitor how quickly you form habits.</p>
            <img src="/img/personality/discount16.png" style={{ width: "80%" }} />
            <p className="get__text1">Amazing Guide on Habits</p>
            <p className="get__text2">In addition to a personal habit plan, we bring you a guide to develop prolific skills and get quick results.</p>
            <button className="successed__button" style={{ marginTop: 0 }} onClick={() => setShow(true)}>Get my plan</button>

            <p className="personality__text" style={{ width: "90%", marginBottom: 43, marginTop: 70 }}>Be the best version of <span style={{ color: '#4380FF' }}>yourself</span></p>
            <div className="personality__version">
                <img src="/img/personality/discount5.png" style={{ width: 120 }} />
                <p className="version__text1">Today</p>
                <img src="/img/personality/discount7.png" style={{ width: 15 }} />
                <img src="/img/personality/discount6.png" style={{ width: 120 }} />
                <p className="version__text1">In 28 days</p>
                <p className="version__text2">You will improve health, life satisfaction, work performance and relationships with others</p>
                <a href="#getplan" className="version__button">Get my plan</a>
            </div>
            <p className="personality__text" style={{ width: "90%", marginBottom: 43, marginTop: 70, textAlign: 'left' }}><span style={{ color: '#4380FF' }}>Proven</span> side benefits </p>
            <Accordion>
                <Card style={{ borderRadius: 20 }}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setAcc1(!acc1)}><div style={{ position: "absolute", height: 68, width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0, top: 0 }}><img alt="img" src={`/img/personality/${(acc1) ? "minus" : "plus"}.png`} style={{ height: 30, width: 30 }} /></div>Life quality increase</Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"><Card.Body>Our life is defined by a sum of positive and negative patterns. Our good habits account for 84% of our success.
</Card.Body></Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion>
                <Card style={{ borderRadius: 20 }}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setAcc2(!acc2)}><div style={{ position: "absolute", height: 68, width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0, top: 0 }}><img alt="img" src={`/img/personality/${(acc2) ? "minus" : "plus"}.png`} style={{ height: 30, width: 30 }} /></div>Improved brain work</Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"><Card.Body>According to Harvard Health Letter, better habits lead to better brain and body health. It can also help with fighting anxiety or depression.</Card.Body></Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion>
                <Card style={{ borderRadius: 20 }} >
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setAcc3(!acc3)}><div style={{ position: "absolute", height: 68, width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0, top: 0 }}><img alt="img" src={`/img/personality/${(acc3) ? "minus" : "plus"}.png`} style={{ height: 30, width: 30 }} /></div>Better health</Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"><Card.Body>The more energy you save, the better health you have. The best way to minimize the time spent on a process is to do it by habit.</Card.Body></Accordion.Collapse>
                </Card>
            </Accordion>
            <div className="successed" id="getplan" style={{ paddingBottom: 14 }}>
                <p className="successed__text1" style={{ width: "80%" }}>Habits raise our chance <span style={{ color: '#4380FF' }}> to succeed!</span></p>
                <p className="get__text2" style={(plan) ? { marginBottom: 25 } : { marginBottom: 25, opacity: 0 }}>7.99 US$/week after trial</p>
                <img src="/img/personality/personality33.png" style={{ width: '90%' }} />
                <div className={(!plan) ? "billing__plan billing__plan--active" : "billing__plan billing__plan--unactive"} onClick={() => setPlan(0)}>
                    <div className="plan__left-side">
                        <img src={`/img/utils/pick_${(!plan) ? "active" : "unactive"}.png`} style={{ width: 20 }} />
                    </div>
                    <div className="plan__right-side">
                        <div className="plan__text1"><p> Lifetime access</p><p>29.99$</p></div>
                        <p className="plan__text2">Personal habit plan &<br /> HabitBox app lifetime access</p>
                    </div>
                </div>
                <div className={(plan) ? "billing__plan billing__plan--active" : "billing__plan billing__plan--unactive"} onClick={() => setPlan(1)}>
                    <div className="plan__left-side">
                        <img src={`/img/utils/pick_${(plan) ? "active" : "unactive"}.png`} style={{ width: 20 }} />
                    </div>
                    <div className="plan__right-side">
                        <div className="plan__text1"><p> 1 month plan</p><p className={(plan) ? "price-on-sticker price-on-sticker--active" : "price-on-sticker price-on-sticker--unactive"}>0.99 first<br /> week</p><img src="/img/utils/price.png" style={{ width: 100, height: 70, marginTop: -34 }} /></div>
                        <p className="plan__text2">Personal habit plan & <br />HabitBox app access</p>
                    </div>
                </div>
                <img src="/img/utils/222.png" style={{ width: '75%', marginTop: 5 }} />
                <button className="successed__button" style={{ marginBottom: 0, width: '90%' }} onClick={() => setShow(true)}>{plan ? "Start trial" : "Get My Plan"}</button>
            </div>
            <p className="get__text2" style={(plan) ? { marginBottom: 25, marginTop: 10 } : { marginBottom: 25, marginTop: 10, opacity: 0 }}>Cancel anytime · $31.96 billed monthly</p>
            <p className="personality__text" style={{ width: "90%", marginBottom: 30, marginTop: 0 }}>100% money-back <span style={{ color: '#4380FF' }}> guarantee💵</span></p>
            <p className="personality__text2">If you feel that your plan won’t work for you, we guarantee to give you money back in <span style={{ color: '#4380FF' }}>14 days</span>.<br /><br /> But you know, there’s no way it will dissapoint you ;) </p>
            <Footer />
            <MyVerticallyCenteredModal
                show={show}
                subscription={plan}
                onHide={() => { handleClose(); props.discount() }}
                price={(plan) ? price2 : price}
                link={`/discount?email=${props.user.email}&firstClicked=${btoa(props.user.firstClicked)}`}
                successRedirect={'/upsale2'}
            />
        </div>
    )
}

export default connect(state => ({ questions: state.questions, user: state.user }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    },
    discount: () => {
        dispatch({ type: 'DISCOUNT' })
    },
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setFirstClick:(payload) =>{
        dispatch({type:'SET_FIRST_CLICK',payload})
    }
}))(withRouter(Personality))