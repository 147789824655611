import React, { useEffect, useState } from 'react'
// import { withRouter } from 'react-router'
import './Home.scss'
// import { connect } from 'react-redux'
import { ConfirmButton, Header } from '../exporter'
import MyVerticallyCenteredModal from './Modal'
import { Redirect, withRouter } from 'react-router';
import { amp, ampProps, ampSet } from '../../utils/amplitude';
import { Card, Accordion } from 'react-bootstrap'
import Footer from '../Footer/Footer';
import { connect } from 'react-redux';


function Home(props) {
    const [acc1, setAcc1] = useState(false)
    const [acc2, setAcc2] = useState(false)
    const [acc3, setAcc3] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [redirect,setRedirect] = useState(false)
    const openInNewWindow = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
    useEffect(()=>{
        if(redirect){
            amp('onboarding_completed')
        }
    },[redirect])
    useEffect(()=>{
        setTimeout(()=>{
            amp('onboarding_shown')
        },0)
    },[])
    if(redirect) return <Redirect push to='/question/1'/>
    return (
        <>
            <div className="personality home" style={{ background: 'white' }}>
                <div className="home__header">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <img className="header__img" src="logo512.png" />
                        <p className="header__text">HabitBox</p>
                    </div>
                    <p className="home__text1">Grow fine habits</p>
                    <p className="home__text2">With a personal helper which is perfect for setting goals, tracking your progress, and motivating yourself to new heights!</p>
                    <button className="home__button1" onClick={() => setRedirect(true)}>Try for free</button>
                    <img src="/img/personality/personality33.png" style={{ width: '100%' }} />
                </div>
                <div className="personality__day">
                    <p className="day__header">We are what we do <span style={{ color: '#4380FF' }}>every day</span></p>
                    <p className="day__text1">To successfully form a habit, you need to:</p>
                    <div className="day__picks">
                        <div className="pick">
                            <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Get a plan</p>
                        </div>
                        <div className="pick">
                            <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Define routine</p>
                        </div>
                        <div className="pick">
                            <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Set a trigger</p>
                        </div>
                        <div className="pick">
                            <img src="/img/personality/personality20.png" className="pick__img" /><p className="pick__text">Reward yourself</p>
                        </div>
                    </div>
                    <p className="day__text2" style={{ marginBottom: 40 }}>Also, you need to set a clear goal in mind. We'll help you form and take control over your habits, and lead you to the patterns of a happy life. <br /><br />By following personal plan, you'll form good habits for a meaningful life.</p>
                </div>
                <img src="/img/personality/personality27.png" style={{ width: '100%' }} />
                <p className="personality__text" style={{ marginTop: 50, marginBottom: 30 }}>What you <span style={{ color: '#4380FF' }}>get</span></p>
                <img src="/img/personality/personality32.png" style={{ width: "80%" }} />
                <p className="get__text1">Premium Access</p>
                <p className="get__text2">You’ll get premium access to app that helps you plan your schedule for new healthy habits.</p>
                <img src="/img/personality/personality31.png" style={{ width: "80%" }} />
                <p className="get__text1">Progress Tracker </p>
                <p className="get__text2">You can track your progress each time you need. Monitor how quickly you form habits.</p>
                <img src="/img/personality/discount16.png" style={{ width: "80%" }} />
                <p className="get__text1">Amazing Guide on Habits</p>
                <p className="get__text2">In addition to a personal habit plan, we bring you a guide to develop prolific skills and get quick results.</p>
                <button className="successed__button" style={{ marginTop: 0 }} onClick={() => setRedirect(true)}>Try for free</button>
                <p className="personality__text" style={{ width: "80%", marginBottom: 20, marginTop: 70 }}>Grow <span style={{ color: '#4380FF' }}>healthy habits </span> right now</p>
                <p className="home__text3" style={{width:"85%",marginTop:15}}>Try effective habitbox approach. <b>Spend 10 minutes</b> daily on challenges.</p>
                <div className="personality__version">
                    <img src="/img/0.png" style={{ width: 120,marginTop:20 }} />
                    <p className="version__text1" style={{marginTop:10}}>Today</p>
                    <img src="/img/personality/discount7.png" style={{ width: 15 }} />
                    <img src="/img/1.png" style={{ width: 120,marginTop:10 }} />
                    <p className="version__text1" style={{marginTop:10}}>In 28 days</p>
                    <p className="version__text2">Raise to the tops with HabitBox</p>
                    <a href="#getplan" className="version__button" onClick={() => setRedirect(true)}>Try for free</a>
                </div>
                <p className="personality__text" style={{ width: "90%", marginBottom: 43, marginTop: 70, textAlign: 'left' }}><span style={{ color: '#4380FF' }}>Proven</span> side benefits </p>
                <Accordion>
                    <Card style={{ borderRadius: 20 }}>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setAcc1(!acc1)}><div style={{ position: "absolute", height: 68, width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0, top: 0 }}><img alt="img" src={`/img/personality/${(acc1) ? "minus" : "plus"}.png`} style={{ height: 30, width: 30 }} /></div>Life quality increase</Accordion.Toggle>
                        <Accordion.Collapse eventKey="0"><Card.Body>Our life is defined by a sum of positive and negative patterns. Our good habits account for 84% of our success.
</Card.Body></Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion>
                    <Card style={{ borderRadius: 20 }}>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setAcc2(!acc2)}><div style={{ position: "absolute", height: 68, width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0, top: 0 }}><img alt="img" src={`/img/personality/${(acc2) ? "minus" : "plus"}.png`} style={{ height: 30, width: 30 }} /></div>Improved brain work</Accordion.Toggle>
                        <Accordion.Collapse eventKey="0"><Card.Body>According to Harvard Health Letter, better habits lead to better brain and body health. It can also help with fighting anxiety or depression.</Card.Body></Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion>
                    <Card style={{ borderRadius: 20 }} >
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setAcc3(!acc3)}><div style={{ position: "absolute", height: 68, width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', right: 0, top: 0 }}><img alt="img" src={`/img/personality/${(acc3) ? "minus" : "plus"}.png`} style={{ height: 30, width: 30 }} /></div>Better health</Accordion.Toggle>
                        <Accordion.Collapse eventKey="0"><Card.Body>The more energy you save, the better health you have. The best way to minimize the time spent on a process is to do it by habit.</Card.Body></Accordion.Collapse>
                    </Card>
                </Accordion>
                <div className="home__header" style={{background:'white',marginBottom:24}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <img className="header__img" src="logo512.png" />
                        <p className="header__text">HabitBox</p>
                    </div>
                </div>
                <p className="home__text4">Your “I wish I coulds” become a reality</p>
                <p className="successed__text2" style={{ fontWeight: 500,width:'90%',textAlign:'center  ' }}><img src="/img/personality/personality30.png" style={{ width: 48 }} /><span style={{ color: '#4380FF' }}>92%</span> of our clients formed habits of successful life by following habit making plan!</p>
                <button className="successed__button" onClick={() =>setRedirect(true)}>Try for free</button>
                <div className="sale" style={{ background: 'white' }}>
                    <div className="links">
                        <p onClick={() => openInNewWindow('https://mail.google.com/mail/?view=cm&fs=1&to=support@habit-box.me')} className="links__link">Contact us</p>
                        <p onClick={() => openInNewWindow('/pdf/Policy.pdf')} className="links__link">Privacy Policy</p>
                        <p onClick={() => openInNewWindow('/pdf/Terms.pdf')} className="links__link">Terms of use</p>
                    </div>
                    <p style={{ marginTop: 20 }} onClick={() => openInNewWindow('/pdf/Subsription.pdf')} className="links__link">Subsription policy</p>
                    <p className="sale__footer">
                        GM Unicorn Corporation Limited Florinis 7,Greg Tower,2nd Floor, 1065, Nicosia, Cyprus
                            </p>
                </div>
            </div>
            {/* <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            /> */}
            {/* <Redirect to='/question/1'/> */}
        </>
    )
}
// export default Home
export default connect(state => ({ state }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(Home))
{/* <ConfirmButton active={true} text={false} submit={() => setModalShow(true)} /> */ }