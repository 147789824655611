import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { ampProps, ampSet } from '../../../utils/amplitude'
import { ConfirmButton, Header, QuestionBox } from '../../exporter'
function TextAnswers(props) {
    const [active, setActive] = useState(0)
    const [button, setButton] = useState(false)
    const [animation, setAnimation] = useState(false)
    const [redirect,setRedirect] = useState(false)
    useEffect(() => {
        if (active) {
            setButton(true)
        } else {
            setButton(false)
        }
    }, [active])
    const activeHandler = (index) => {
        if (index === active) {
            setActive(0)
        } else {
            setActive(index)
        }
        setTimeout(()=>{
        if(props.questions[props.match.params.id-1].tag && props.questions[props.match.params.id-1].tag === 'age'){
            ampSet('age',props.questions[props.match.params.id-1].answers[index-1])
        }
        props.singleAnswer({id:props.match.params.id-1,number:index-1})
        setAnimation(true)
        setTimeout(() => {
            setRedirect(true)
            setActive(false)
            setAnimation(false)
        }, 500)
    },500)
    
    }
    if (redirect) return <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`} />
    return (
        <>
         <Header back={true} question={`${props.match.params.id}/${props.questions.length}`} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
         <div className={(animation) ? "text-answers__block text-answers__block--fadeout" : "text-answers__block text-answers__block--fadein"}>
            <QuestionBox title={props.questions[parseInt(props.match.params.id - 1)].title} text={props.questions[parseInt(props.match.params.id - 1)].secondText} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
            <div className="block__container" >
                {props.questions[parseInt(props.match.params.id - 1)].answers.map((elem, index) => {
                    return (
                        <div className={(active === index + 1) ? "container__item container__item--active" : "container__item container__item--unactive"} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} onClick={() => activeHandler(index + 1)}>
                            <p className={(active === index + 1) ? "container-item__text container-item__text--active" : "container-item__text container-item__text--unactive"} style={{color:"#4380FF",marginLeft:10, fontWeight:600}}>{elem}&nbsp;</p>
                            <p className={(active === index + 1) ? "container-item__text container-item__text--active" : "container-item__text container-item__text--unactive"} style={{fontWeight:600}}>{props.questions[parseInt(props.match.params.id - 1)].text}</p>
                        </div>
                    )
                })}
            </div>
            {/* <ConfirmButton submitLink={() => answer()} active={button} text={props.questions[parseInt(props.match.params.id - 1)].skip} button={props.questions[parseInt(props.match.params.id - 1)].buttonText} link={`/question/${parseInt(props.match.params.id) + 1}`} /> */}
        </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    },
    singleAnswer : (payload) =>{
        dispatch({type:'SINGLE_ANSWER', payload})
    }
}))(withRouter(TextAnswers))