import { Modal, Button, Spinner } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import './BillingModal.scss'
import { amp, ampProps } from '../../utils/amplitude'
import { v4 as uuidv4 } from 'uuid'


function MyVerticallyCenteredModal(props) {
    const [src, setSrc] = useState('')
    const [redirect, setRedirect] = useState(false)
    const inputEl = useRef(null);
    const [counter, setCounter] = useState(0)
    console.log(process.env)
    const listener = async (msg) => {
        console.log(msg)
        if (
            msg.data.type === 'orderStatus' &&
            msg.data.response.order.status === 'approved'
        ) {
            //    await trackPurchase({...props, price:localStorage.getItem('hello')})
            props.bill()
            props.setBillPrice({ price: props.price })
            props.setCardToken({ token: msg.data.response.transactions[Object.keys(msg.data.response.transactions)[0]].card.card_token.token })
            setRedirect(true)
        }
        if (
            msg.data.type === 'orderStatus' &&
            msg.data.response.order.status === 'declined'
        ) {
            window.open(props.location.pathname, "_top")
        }
    }
    useEffect(() => {
        window.addEventListener('message', listener);
    }, [])
    useEffect(() => {
        if (props.show === false) {
            setCounter(0)
        }
        if (props.show === true) {
            amp('checkout_shown')
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/paypal/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}${(props.price === 99) ? '/subscription' : (props.price === 69) ? '/subscription/lower' : ''}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_PAYPAL_TEST_MERCHANT : process.env.REACT_APP_PAYPAL_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: props.price, email: props.state.user.email || 'emailnotentered@gmail.com' })

            }).then((res) => res.json()).then((res) => {
                console.log(res)
                const script = document.createElement("script");
                script.src = res.url;
                inputEl.current.addEventListener('order-processed', async function (e) {
                    console.log('order-processed', e);
                    console.log('order-processed', e.detail.data.order.status);
                    if (e.detail.data.order.status === 'approved') {
                        // await trackPurchase({...props, price:localStorage.getItem('hello')})
                        props.bill()
                        props.setBillPrice({ price: props.price })
                        props.setPayPalToken({ token: e.detail.data.order.token })
                        setRedirect(true)
                    } else {
                        window.open(props.location.pathname, '_top', 'noopener,noreferrer')
                    }
                }, false)
                document.body.appendChild(script);
                setCounter(counter + 1)
            })
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/card/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}${(props.price === 99) ? '/subscription' : (props.price === 69) ? '/subscription/lower' : ''}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_CARD_TEST_MERCHANT : (props.subscription) ? process.env.REACT_APP_SUBSCRIPTION_LIVE_MERCHANT : process.env.REACT_APP_CARD_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: props.price, email: props.state.user.email || 'emailnotentered@gmail.com' })

            }).then((res) => res.json()).then((res) => {
                setSrc(res.url)
            })


        }
    }, [props.show])

    if (redirect) return <Redirect push to={{ pathname: '/register', state: { successRedirect: (props.successRedirect) ? props.successRedirect : '/success' } }} />
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header style={{ flexDirection: 'row-reverse', borderBottom: 0 }}>
                {(props.link) ? <Link push to={props.link}><img className="header-icon" src="img/Union3.png" style={{ height: 16, width: 16 }} alt="img" onClick={() => props.onHide()} /></Link> : <img className="header-icon" src="img/Union3.png" style={{ height: 16, width: 16 }} alt="img" onClick={() => { props.onHide() }} />}
            </Modal.Header>
            <Modal.Body style={{ paddingTop: 0 }}>
                <div className="personality__modal">
                    {(props.dropped) ?
                        <>
                            <div className="personality__modal__charge" style={{ marginBottom: 10, paddingBottom: 8 }}>
                                <span className="modal-checkout">Price per day</span><span className="modal-price">${(props.price / 100 / 28).toFixed(2)}</span>
                            </div>
                            <div className="personality__modal__charge2">
                                <span className="modal-checkout">Total:</span><span className="modal-price">${(props.price / 100).toFixed(2)}</span>
                            </div>

                        </>
                        :
                        <>
                            <div className="personality__modal__charge">
                                <span className="modal-checkout">Checkout</span><span className="modal-price">Total : <b>${props.price / 100}</b> </span>
                            </div>
                        </>
                    }

                    <div className="personality__modal__buttons" style={(counter === 1) ? { zIndex: 1 } : { zIndex: 0 }}>
                        <><div className="modal__button" ref={inputEl} id="paypal-button" style={{ border: 'none', background: 'white' }} ></div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12, marginBottom: 12 }}><div className="line"></div><p className="modal__button__text">or</p><div className="line"></div></div></>
                        <iframe src={src} width="100%" height="400px" frameBorder="0"></iframe>
                        <p className="secured-payment">Your payment is secured</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default connect(state => ({ state }), dispatch => ({
    bill: () => {
        dispatch({ type: 'SET_BILL' })
    },
    setBillPrice: (payload) => {
        dispatch({ type: 'SET_PAID_PRICE', payload })
    },
    setCardToken: (payload) => {
        dispatch({ type: 'SET_CARD_TOKEN', payload })
    },
    setPayPalToken: (payload) => {
        dispatch({ type: 'SET_PAYPAL_TOKEN', payload })
    }
}))
    (withRouter(MyVerticallyCenteredModal));
// "https://my-fantasy2.herokuapp.com/api/card/live?email=test@gmail.com&amount=50"