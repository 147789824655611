import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { amp, ampProps, ampSet } from '../../utils/amplitude'
import { trackPurchase } from './trackPurchase'
import './Upsale.scss'

function Upsale2(props) {
    const [successRedirect,setSuccessRedirect] = useState(false)
    const [price,setPrice] = useState(999)
    const [disabled,setDisabled] = useState(false)
    
    useEffect(()=>{
        amp('upsell_shown_ch')
    },[])
    const upgradeOrder = () => {
        ampSet('upsale_ch','upgraded')
        setDisabled(!disabled)
        console.log(props.user)
        if (props.user.cardToken && !props.user.upsale2) {
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin)?process.env.REACT_APP_TEST_SERVER:process.env.REACT_APP_LIVE_SERVER}/api/card/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}/recure`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_CARD_TEST_MERCHANT : process.env.REACT_APP_CARD_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: price, email: props.user.email || 'emailnotentered@gmail.com', token: props.user.cardToken })

            }).then((res) => {
                fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/challenge`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "auth": process.env.REACT_APP_MAILFIRE
                    },
                    body: JSON.stringify({
                        email: props.user.email
                    })
                }).then((res) => {
                    trackPurchase(price,'purchase_ch')
                    props.setUpsale()
                    props.setBillPrice({ price: props.user.price + price })
                    setSuccessRedirect(true)
                    setDisabled(!disabled)
                })
            })
        } else if (props.user.paypalToken && !props.user.upsale2) {
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin)?process.env.REACT_APP_TEST_SERVER:process.env.REACT_APP_LIVE_SERVER}/api/paypal/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}/recure`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_PAYPAL_TEST_MERCHANT : process.env.REACT_APP_PAYPAL_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: price, email: props.user.email || 'emailnotentered@gmail.com', token: props.user.paypalToken })

            }).then((res) => {
                fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/challenge`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "auth": process.env.REACT_APP_MAILFIRE
                    },
                    body: JSON.stringify({
                        email: props.user.email
                    })
                }).then((res) => {
                    trackPurchase(price,'purchase_ch')
                    props.setUpsale()
                    props.setBillPrice({ price: props.user.price + price })
                    setSuccessRedirect(true)
                    setDisabled(!disabled)
                })
            })
        } else {
            setSuccessRedirect(true)
            setDisabled(!disabled)
        }
    }
    if(successRedirect) return  <Redirect push to={{ pathname: '/success' }} />
    return (
        <div className="upsale__screen1" style={{background:' #FFFCF8'}}>
            <p className="screen1__text1">Add <span style={{color:'#2e71ff'}}>100+ days of challenges</span> workbook in personalized habit plan</p>
            <div className="screen1__lineblock">
                <div className="lineblock__check">
                    <img className="check__img" src='/img/pick.png' />
                </div>
                <p className="lineblock__text" >Keep track of your daily progress</p>
            </div>
            <div className="screen1__lineblock">
                <div className="lineblock__check">
                    <img className="check__img" src='/img/pick.png' />
                </div>
                <p className="lineblock__text" >Build up your motivation</p>
            </div>
            <div className="screen1__lineblock">
                <div className="lineblock__check">
                    <img className="check__img" src='/img/pick.png' />
                </div>
                <p className="lineblock__text" >Develop new useful habits</p>
            </div>
            <img className="upsale__img" src='/img/upsale2.png'/>
            <div className="upsale__whiteblock" style={{background:'#FEF7ED'}}>
                <p className="whiteblock__text1"><strike style={{color:'#FF4B12'}}>USD 25</strike> USD {price/100} will be added to your order</p>
                <button className="whiteblock__button" style={(disabled)?{pointerEvents:'none',opacity:0.5}:{}} onClick={()=>upgradeOrder()}>Upgrade My Order </button>
                <Link push to="/success" onClick={()=> ampSet('upsale_ch','declined')}className="whiteblock__undertext" >No, thank you</Link>
            </div>
        </div>
    )
}
export default connect(state => ({ user: state.user }), dispatch => ({
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setReload: () => {
        dispatch({ type: 'SET_RELOAD' })
    },
    setUpsale:() =>{
        dispatch({type:'SET_UPSALE_1'})
    },
    setBillPrice:(payload) =>{
        dispatch({type:'SET_PAID_PRICE',payload})
    }
}))(withRouter(Upsale2))
