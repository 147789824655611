import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import './BillingModal.scss'
import { amp, ampProps } from '../../utils/amplitude'
import { v4 as uuidv4 } from 'uuid'

const links = {
    'Cleaning': 'https://habit-box-web.s3.us-east-2.amazonaws.com/cleaning.pdf',
    'Nutrition': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Nutrition.pdf',
    'Personal Development': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Personal+Development.pdf',
    'Self-Care': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Self-Care.pdf',
    'Sex & Love': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Sex+%26+Love.pdf',
    'Sport': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Sport.pdf',
    'Mental & Physical Health': 'https://habit-box-web.s3.us-east-2.amazonaws.com/Mental%26Physical+Health.pdf'
}

const prices = {
    '0': '2999',
    '69': '3196',
    '99': '3196'
}


const trackPurchase = async (props) => {
    console.log(props)
    let id = uuidv4()
    ampProps('purchase', { value: props.price / 100 })
    window.gtag("event", "purchase", { transaction_id: id, currency: "USD", value: prices[props.price] / 100 || props.price / 100 })
    window.fbq('track', 'AddPaymentInfo', { currency: "USD", value: prices[props.price] / 100 || props.price / 100 });
    window.fbq('track', 'AddToCart', { currency: "USD", value: prices[props.price] / 100 || props.price / 100 });
    window.fbq('track', 'InitiateCheckout', { currency: "USD", value: prices[props.price] / 100 || props.price / 100 });
    // window.gtag2("event", "purchase", {transaction_id: id, currency: "USD", value: prices[props.price] / 100 || props.price / 100 })
    window.fbq('track', 'Purchase', { currency: "USD", value: prices[props.price] / 100 || props.price / 100 });
    fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/charge`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "auth": process.env.REACT_APP_MAILFIRE
        },
        body: JSON.stringify({
            email: props.state.user.email,
            pdf: (props.state.user.firstClicked) ? links[props.state.user.firstClicked] : links['Cleaning']
        })
    })
    fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/app`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "auth": process.env.REACT_APP_MAILFIRE
        },
        body: JSON.stringify({
            email: props.state.user.email
        })
    })
}

function PurchaseRegister(props) {
    useEffect(() => {
        console.log(props)
        if (!localStorage.getItem('purchase')) {
            localStorage.setItem('purchase', 'true')
            trackPurchase({ ...props, ...(localStorage.getItem('hello') ? { price: localStorage.getItem('hello') } : { price: props.state.user.price }) })
        }
        localStorage.removeItem('hello')
    }, [])
    return (
        <>
            <Redirect to={{ pathname: props.location.state.successRedirect }} />
        </>
    )
}
export default connect(state => ({ state }), dispatch => ({
}))
    (withRouter(PurchaseRegister));