import React from 'react'
import { Email, QuestionController } from './components/exporter'
import PersonalityDefault1 from './components/personality2/Personality'
import Personality1 from './components/personality/Personality'
import { Route, Switch } from 'react-router'
import Success from './components/Success/Success'
import Discount1 from './components/Discount/Discount1'
import DiscountDefault1 from './components/Discount2/Discount1'
import Home from './components/Home2/Home'
import HomeDefault from './components/Home/Home'
import PurchaseRegister from './components/BillingModal/PurchaseRegister'
import Upsale1 from './components/Upsales/Upsale'
import Upsale2 from './components/Upsales/Upsale2'


export default function Router({ type }) {
    if (!localStorage.getItem('flow_type')) {
        localStorage.setItem('flow_type',type)
    }
    switch (localStorage.getItem('flow_type')) {
        case "1": {
            return (
                <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/question/:id" component={QuestionController} exact />
                    <Route path="/email" component={Email} exact />
                    <Route path="/personality" component={PersonalityDefault1} exact />
                    <Route path="/success" component={Success} exact />
                    <Route path='/discount' component={Discount1} />
                    <Route path='/register' component={PurchaseRegister} />
                    <Route path='/upsale' component={Upsale1} />
                    <Route path='/upsale2' component={Upsale2} />
                    <Route component={Home} />
                </Switch>
            )
        }
        default: {
            return (
                <Switch>
                    <Route path="/" component={HomeDefault} exact />
                    <Route path="/question/:id" component={QuestionController} exact />
                    <Route path="/email" component={Email} exact />
                    <Route path="/personality" component={Personality1} exact />
                    <Route path="/success" component={Success} exact />
                    <Route path='/discount' component={DiscountDefault1} />
                    <Route path='/register' component={PurchaseRegister} />
                    <Route path='/upsale' component={Upsale1} />
                    <Route path='/upsale2' component={Upsale2} />
                    <Route component={HomeDefault} />
                </Switch>
            )
        }

    }
}