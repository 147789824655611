import React, { useEffect, useState } from 'react'
// import { withRouter } from 'react-router'
import './Home.scss'
// import { connect } from 'react-redux'
import { ConfirmButton, Header } from '../exporter'
import MyVerticallyCenteredModal from './Modal'
import { Redirect, withRouter } from 'react-router';
import { amp, ampProps, ampSet } from '../../utils/amplitude';
import { Card, Accordion } from 'react-bootstrap'
import Footer from '../Footer/Footer';
import { connect } from 'react-redux';


function Home(props) {
    const [acc1, setAcc1] = useState(false)
    const [acc2, setAcc2] = useState(false)
    const [acc3, setAcc3] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const openInNewWindow = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
    useEffect(()=>{
        if(redirect){
            amp('onboarding_completed')
        }
    },[redirect])
    useEffect(()=>{
        setTimeout(()=>{
            amp('onboarding_shown')
        },0)
    },[])
    if (redirect) return <Redirect push to='/question/1' />
    return (
        <>
            <Redirect push to='/question/1' />
        </>
    )
}
// export default Home
export default connect(state => ({ state }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(Home))
{/* <ConfirmButton active={true} text={false} submit={() => setModalShow(true)} /> */ }