import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ConfirmButton, Header, QuestionBox } from '../../exporter'
function EightAnswers(props) {
    const [active, setActive] = useState([false, false, false, false, false, false])
    const [button, setButton] = useState(false)
    console.log(props.questions[parseInt(props.match.params.id - 1)].skip)
    const answer = () =>{
        console.log('hello')
        setActive([false, false, false, false, false, false])
    }
    const activeHandler = (index) => {
        let activeItems = [...active]
        activeItems[index] = !activeItems[index]
        setActive(activeItems)
    }
    useEffect(() => {
        let flag = false
        active.forEach((elem) => {
            if (elem) {
                setButton(active)
                flag = true
            }
        })
        if (!flag) setButton(false)
    }, [active])
    return (
        <>
         <Header back={true} question={`${props.match.params.id}/${props.questions.length}`} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
        <div className="eight-answers__block">
            <QuestionBox title={props.questions[parseInt(props.match.params.id - 1)].title} text={props.questions[parseInt(props.match.params.id - 1)].secondText} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
            <div className="block__container">
                {props.questions[parseInt(props.match.params.id - 1)].answers.map((elem, index) => {
                    return (
                        <div className={(active[index]) ? "container__item container__item--active" : "container__item container__item--unactive"} style={{display:'flex',alignItems:'center',justifyContent:'center'}}onClick={() => activeHandler(index)}>
                            <p className={(active[index]) ? "container__text container__text--active" : "container__text container__text--unactive"}>{elem}</p>
                        </div>)
                })}
            </div>
            <ConfirmButton submitLink={()=>answer()} text={props.questions[parseInt(props.match.params.id - 1)].skip} active={button} button={props.questions[parseInt(props.match.params.id - 1)].buttonText} link={`/question/${parseInt(props.match.params.id)+1}`}/>
        </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(EightAnswers))