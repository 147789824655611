import React from 'react'
import './Footer.scss'

export default function Footer() {
    const openInNewWindow = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer')
    }
    return (
        <div className="sale" style={{ background: 'white' }}>
            <p className="sale__guarantee" style={{ justifyContent: 'center' }}>Pay safe & secure</p>
            <div style={{ display: 'flex', justifyContent: 'center', width: '80%', marginBottom: 11 }}>

                <img src='/img/Footer.png' style={{ width: '100%', height: '50%' }} />
            </div>
            <div className="links">
                <p onClick={() => openInNewWindow('https://mail.google.com/mail/?view=cm&fs=1&to=support@habit-box.me')} className="links__link">Contact us</p>
                <p onClick={() => openInNewWindow('/pdf/Policy.pdf')} className="links__link">Privacy Policy</p>
                <p onClick={() => openInNewWindow('/pdf/Terms.pdf')} className="links__link">Terms of use</p>
            </div>
            <p style={{marginTop:20}} onClick={() => openInNewWindow('/pdf/Subsription.pdf')} className="links__link">Subsription policy</p>
            <p className="sale__footer">
                GM Unicorn Corporation Limited Florinis 7,Greg Tower,2nd Floor, 1065, Nicosia, Cyprus
                            </p>
        </div>
    )
}